import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AuthContext, logout } from '../context/AuthContext';
import routes from '../routes';

const TopNav = ({ toggleSidebar }) => {
    const [accountDropdownOpen, setAccountDropdownOpen] = React.useState(false);
    const user = React.useContext(AuthContext);
    const location = useLocation();
    return (
        <nav
            className="relative flex flex-wrap items-center justify-between px-0 py-2 mx-6 transition-all ease-in shadow-none duration-250 rounded-2xl lg:flex-nowrap lg:justify-start"
        >
            <div className="flex items-center justify-between w-full px-4 py-1 mx-auto flex-wrap-inherit">
                <nav>
                    <ol className="flex flex-wrap pt-1 mr-12 bg-transparent rounded-lg sm:mr-16">
                        <li className="text-sm leading-normal">
                            <a className="text-white opacity-50">Pages</a>
                        </li>
                        <li
                            className="text-sm pl-2 capitalize leading-normal text-white before:float-left before:pr-2 before:text-white before:content-['/']"
                            aria-current="page"
                        >
                            {routes.find((route) => route.path == location.pathname)?.name}
                        </li>
                    </ol>
                    <h6 className="mb-0 font-bold text-white capitalize">{routes.find((route) => route.path == location.pathname)?.name}</h6>
                </nav>

                <div className="flex items-center mt-2 grow sm:mt-0 sm:mr-6 md:mr-0 lg:flex lg:basis-auto">
                    <div className="flex items-center md:ml-auto md:pr-4">
                        {/* <div className="relative flex flex-wrap items-stretch w-full transition-all rounded-lg ease">
                            <span className="text-sm ease leading-5.6 absolute z-50 -ml-px flex h-full items-center whitespace-nowrap rounded-lg rounded-tr-none rounded-br-none border border-r-0 border-transparent bg-transparent py-2 px-2.5 text-center font-normal text-slate-500 transition-all">
                                <i className="fas fa-search"></i>
                            </span>
                            <input
                                type="text"
                                className="pl-9 text-sm focus:shadow-primary-outline ease w-1/100 leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 dark:bg-slate-850 dark:text-white bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none focus:transition-shadow"
                                placeholder="Type here..."
                            />
                        </div> */}
                    </div>
                    <ul className="flex flex-row justify-end pl-0 mb-0 list-none md-max:w-full">
                        {/* <!-- <li className="flex items-center">
<a className="inline-block px-8 py-2 mb-0 mr-4 text-xs font-bold text-center text-blue-500 uppercase align-middle transition-all ease-in bg-transparent border border-blue-500 border-solid rounded-lg shadow-none cursor-pointer leading-pro hover:-translate-y-px active:shadow-xs hover:border-blue-500 active:bg-blue-500 active:hover:text-blue-500 hover:text-blue-500 tracking-tight-rem hover:bg-transparent hover:opacity-75 hover:shadow-none active:text-white active:hover:bg-transparent" target="_blank" href="https://www.creative-tim.com/builder/soft-ui?ref=navbar-dashboard&amp;_ga=2.76518741.1192788655.1647724933-1242940210.1644448053">Online Builder</a>
</li> --> */}

                        <li className="relative flex items-center pr-2">
                            {/* <p className="hidden transform-dropdown-show"></p> */}
                            <button
                                className="flex flex-row items-center p-0  text-sm text-white transition-all ease-nav-brand"
                                onClick={() => {
                                    setAccountDropdownOpen(!accountDropdownOpen);
                                }}
                            >
                                <img src={user.user.photoURL} className=" rounded-full h-10 mx-2" referrerPolicy='no-referrer' alt={user.user.displayName} />
                                <span className=" text-lg">{user.user.displayName}</span>
                            </button>

                            <ul
                                className={
                                    "text-sm transform-dropdown before:font-awesome before:leading-default before:duration-350 before:ease lg:shadow-3xl duration-250 min-w-44 before:sm:right-8 before:text-5.5 absolute right-0 top-0 z-50 origin-top list-none rounded-lg border-0 border-solid border-transparent dark:shadow-dark-xl dark:bg-slate-850 bg-white bg-clip-padding text-left text-slate-500  transition-all before:absolute before:right-2 before:left-auto before:top-0 before:z-50 before:inline-block before:font-normal before:text-white before:antialiased before:transition-all before:content-['\f0d8'] sm:-mr-6 lg:absolute lg:right-0 lg:left-auto lg:mt-2 lg:block lg:cursor-pointer " +
                                    (accountDropdownOpen ? ' transform-dropdown-show' : ' opacity-0')
                                }
                            >
                                <li className="relative mb-2">
                                    <button
                                        className="dark:hover:bg-slate-900 dark:text-slate-200 py-2 text-lg ease py-1.2 clear-both block w-full whitespace-nowrap rounded-lg bg-transparent px-4 duration-300 hover:bg-gray-200 hover:text-slate-200 lg:transition-colors"
                                        onClick={() => {
                                            // logout
                                            logout();
                                        }}
                                    >
                                        Logout
                                    </button>
                                </li>
                            </ul>
                        </li>
                        <li className="flex items-center px-4">
                            <a className="p-0 text-sm text-white transition-all ease-nav-brand">
                                <i className="cursor-pointer fa fa-cog"></i>
                            </a>
                        </li>
                        <li className="flex items-center pl-4 xl:hidden">
                            <button className="block p-0 text-sm text-white transition-all ease-nav-brand" onClick={toggleSidebar}>
                                <div className="w-4.5 overflow-hidden">
                                    <i className="ease mb-0.75 relative block h-0.5 rounded-sm bg-white transition-all"></i>
                                    <i className="ease mb-0.75 relative block h-0.5 rounded-sm bg-white transition-all"></i>
                                    <i className="ease relative block h-0.5 rounded-sm bg-white transition-all"></i>
                                </div>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default TopNav;
