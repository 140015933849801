import AddBike from "./views/Dashboard/AddBike";
import Bookings from "./views/Dashboard/Bookings";
import Dashboard from "./views/Dashboard/Dashboard";
import ListBikes from "./views/Dashboard/ListBikes";
import ListUsers from "./views/Dashboard/ListUsers";

const routes = [
    {
        path: '/',
        component: Dashboard,
        name: 'Dashboard',
        icon: 'ni ni-tv-2 text-blue-500',
    },
    {
        path: '/addbike',
        component: AddBike,
        name: 'Add Bike',
        icon: 'ni ni-fat-add text-green-500',
    },
    {
        hidden: true,
        path: '/editbike/:id',
        component: AddBike,
        name: 'Edit Bike',
        icon: 'fa fa-pen text-green-500',
    },
    {
        path: '/listings',
        component: ListBikes,
        name: 'List Bikes',
        icon: 'ni ni-archive-2 text-blue-500',
    },
    {
        path: '/bookings',
        component: Bookings,
        name: 'Bookings',
        icon: 'ni ni-calendar-grid-58 text-orange-500',
    },
    {
        path: '/users',
        component: ListUsers,
        name: 'Users',
        icon: 'ni ni-single-02 text-yellow-500',
    }

];

export default routes;
