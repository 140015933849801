import { Modal } from 'antd';
import React from 'react';

const UserModal = () => {
    // antd modal
    const [isModalVisible, setIsModalVisible] = React.useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <button
                type="button"
                className="flex justify-center mx-2 px-2 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-blue-600 border border-transparent rounded-md hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700"
                onClick={showModal}
            >
                More
            </button>
            <Modal title="Basic Modal" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null} width={800}>
                {
                    // show user details
                    // displayName
                    // dob
                    // email
                    // photoURL
                    // realPhotoURL
                    // drivingLicense
                    // aadhar
                    // drivingLicensePhotoURL
                    // aadharPhotoURL
                    // phone
                    // address
                    // role
                    // status
                    // verified
                    // updatedAt
                    // createdAt
                    // <div flex flex row> <div>name</div> <div>{displayName}</div></div>
                    <>
                        <div className="flex flex-row">
                            <div className="w-1/2">Name</div>
                            <div className="w-1/2">Rahul</div>
                        </div>
                        <div className="flex flex-row">
                            <div className="w-1/2">Email</div>
                            <div className="w-1/2">
                                <a
                                    href="mailto:
                                "
                                >
                                    test@gmail.com
                                </a>
                            </div>
                        </div>
                        <div className="flex flex-row">
                            <div className="w-1/2">Mobile</div>
                            <div className="w-1/2">+91 1234567890</div>
                        </div>
                        <div className="flex flex-row">
                            <div className="w-1/2">Address</div>
                            <div className="w-1/2">Address</div>
                        </div>
                        <div className="flex flex-row">
                            <div className="w-1/2">Role</div>
                            <div className="w-1/2">User</div>
                        </div>
                        <div className="flex flex-row">
                            <div className="w-1/2">Status</div>
                            <div className="w-1/2">Active</div>
                        </div>
                        <div className="flex flex-row">
                            <div className="w-1/2">Verified</div>
                            <div className="w-1/2">Yes</div>
                        </div>
                        <div className="flex flex-row">
                            <div className="w-1/2">Created At</div>
                            <div className="w-1/2">2021-01-01 00:00:00</div>
                        </div>
                        <div className="flex flex-row">
                            <div className="w-1/2">Updated At</div>
                            <div className="w-1/2">2021-01-01 00:00:00</div>
                        </div>
                        {
                            // show user documents as new tab
                            // drivingLicense
                            // aadhar
                            // drivingLicensePhotoURL
                            // aadharPhotoURL
                            <>
                                <div className="flex flex-row">
                                    <div className="w-1/2">Driving License</div>
                                    <div className="w-1/2">
                                        <a href="" target="_blank" rel="noopener noreferrer">
                                            Driving License
                                        </a>
                                    </div>
                                </div>
                                <div className="flex flex-row">
                                    <div className="w-1/2">Aadhar</div>
                                    <div className="w-1/2">
                                        <a href="" target="_blank" rel="noopener noreferrer">
                                            Aadhar
                                        </a>
                                    </div>
                                </div>
                                <div className="flex flex-row">
                                    <div className="w-1/2">Driving License Photo</div>
                                    <div className="w-1/2">
                                        <a href="" target="_blank" rel="noopener noreferrer">
                                            Driving License Photo
                                        </a>
                                    </div>
                                </div>
                                <div className="flex flex-row">
                                    <div className="w-1/2">Aadhar Photo</div>
                                    <div className="w-1/2">
                                        <a href="" target="_blank" rel="noopener noreferrer">
                                            Aadhar Photo
                                        </a>
                                    </div>
                                </div>
                            </>
                        }
                    </>
                }
            </Modal>
        </>
    );
};

const ListUsers = () => {
    return (
        <div>
            <div className="flex flex-wrap -mx-3">
                <div className="w-full max-w-full px-3 shrink-0 md:w-12/12 md:flex-0">
                    {
                        // Users table
                    }

                    <div className="flex flex-col w-full overflow-hidden p-2">
                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="py-2 align-middle ">
                                <div className="shadow overflow-x-auto sm:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-200 overflow-x-auto">
                                        <thead className=" bg-slate-800">
                                            <tr>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Name
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Mobile
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Email
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Status
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className=" divide-y divide-gray-200">
                                            <tr>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="flex items-center">
                                                        <div className="flex-shrink-0 h-10 w-10">
                                                            <img
                                                                className="h-10 w-10 rounded-full"
                                                                src="https://images.unsplash.com/photo-1516802273409-68526ee1bdd6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="ml-4">
                                                            <div className="text-sm font-medium text-white">Jane Cooper</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm text-white">+33 6 00 00 00 00</div>
                                                </td>

                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm text-white">
                                                        <a href="mailto:s@.fr">s@.fr</a>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                        Active
                                                    </span>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 flex flex-row">
                                                    <a href="#" className="text-indigo-600 hover:text-indigo-900">
                                                        Edit
                                                    </a>
                                                    <UserModal />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListUsers;
