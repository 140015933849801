import { message, Spin } from 'antd';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from '../../services/api';

const BikeRow = ({ bike, index, setBikes }) => {
    const deleteBike = async () => {
        try {
            const res = await axios.delete(`/bikes/${bike._id}`);
            if (res.status === 200) {
                message.success('Bike deleted successfully');
                setBikes((prev) => {
                    const newBikes = [...prev];
                    newBikes.splice(index, 1);
                    return newBikes;
                });
            }
        } catch (error) {
            message.error('Something went wrong');
        }
    };

    return (
        <tr>
            <td className="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <div className="flex px-2 py-1">
                    <div>
                        <img
                            src={bike.image}
                            className="inline-flex items-center justify-center mr-4 text-sm text-white transition-all duration-200 ease-in-out h-9 w-9 rounded-xl"
                            alt={bike.name}
                        />
                    </div>
                    <div className="flex flex-col justify-center">
                        <h6 className="mb-0 text-sm leading-normal dark:text-white">{bike.name}</h6>
                        <p className="mb-0 text-xs leading-tight dark:text-white dark:opacity-80 text-slate-400">{bike.rcNumber}</p>
                    </div>
                </div>
            </td>
            <td className="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <p className="mb-0 text-xs font-semibold leading-tight dark:text-white dark:opacity-80">{bike.model}</p>
                <p className="mb-0 text-xs leading-tight dark:text-white dark:opacity-80 text-slate-400">{bike.type}</p>
            </td>
            <td className="p-2 text-sm leading-normal text-center align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                {/* <span className="bg-gradient-to-tl from-emerald-500 to-teal-400 px-2.5 text-xs rounded-1.8 py-1.4 inline-block whitespace-nowrap text-center align-baseline font-bold uppercase leading-none text-white">
                    Online
                </span> */}
                {bike.status === 'active' ? (
                    <span className="bg-gradient-to-tl from-emerald-500 to-teal-400 px-2.5 text-xs rounded-1.8 py-1.4 inline-block whitespace-nowrap text-center align-baseline font-bold uppercase leading-none text-white">
                        Active
                    </span>
                ) : (
                    <span className="bg-gradient-to-tl from-red-500 to-red-400 px-2.5 text-xs rounded-1.8 py-1.4 inline-block whitespace-nowrap text-center align-baseline font-bold uppercase leading-none text-white">
                        Inactive
                    </span>
                )}
            </td>
            <td className="p-2 text-center align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <span className="text-xs font-semibold leading-tight dark:text-white dark:opacity-80 text-slate-400">
                    {
                        // rupees symbol
                        `₹ ${bike.price} / hr`
                    }
                </span>
            </td>
            <td className="p-2 text-center align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <span className="text-xs font-semibold leading-tight dark:text-white dark:opacity-80 text-slate-400">
                    {
                        // rupees symbol
                        bike.outStationPrice?`₹ ${bike.outStationPrice} / hr`:"NOT SET"
                    }
                </span>
            </td>
            <td className="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <Link to={`/editbike/${bike._id}`}>
                    <button className="text-xs mx-1 font-semibold leading-tight dark:text-white dark:opacity-80 text-slate-400">Edit</button>
                </Link>
                {/* <button className="text-xs mx-1 font-semibold leading-tight dark:text-white dark:opacity-80 text-slate-400">Delete</button> */}
            </td>
        </tr>
    );
};

const ListBikes = () => {
    const [bikes, setBikes] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    const getBikes = async () => {
        setLoading(true);
        axios
            .get('/vehicles')
            .then((response) => {
                setBikes(response.data.data);
                setLoading(false);
            })
            .catch((error) => {
                message.error(error.message);
                setLoading(false);
            });
    };

    useEffect(() => {
        getBikes();
    }, []);

    return (
        <div className="flex flex-wrap -mx-3">
            <div className="flex-none w-full max-w-full px-3">
                <div className="relative flex flex-col min-w-0 mb-6 break-words bg-white border-0 border-transparent border-solid shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
                    <div className="p-6 pb-0 mb-0 border-b-0 border-b-solid rounded-t-2xl border-b-transparent">
                        <h6 className="dark:text-white">All Bikes</h6>
                    </div>
                    <div className="flex-auto px-0 pt-0 pb-2">
                        <div className="p-0 overflow-x-auto">
                            <table className="items-center w-full mb-0 align-top border-collapse dark:border-white/40 text-slate-500">
                                <thead className="align-bottom">
                                    <tr>
                                        <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-collapse shadow-none dark:border-white/40 dark:text-white text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                            Bike Info
                                        </th>
                                        <th className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-collapse shadow-none dark:border-white/40 dark:text-white text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                            Model & Type
                                        </th>
                                        <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-collapse shadow-none dark:border-white/40 dark:text-white text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                            Status
                                        </th>
                                        <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-collapse shadow-none dark:border-white/40 dark:text-white text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                            Price
                                        </th>
                                        <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-collapse shadow-none dark:border-white/40 dark:text-white text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                                            Out Station Price 
                                        </th>
                                        <th className="px-6 py-3 font-semibold capitalize align-middle bg-transparent border-b border-collapse border-solid shadow-none dark:border-white/40 dark:text-white tracking-none whitespace-nowrap text-slate-400 opacity-70"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? (
                                        <tr>
                                            <td
                                                colSpan="5"
                                                className="p-2 text-center align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent"
                                            >
                                                <Spin />
                                            </td>
                                        </tr>
                                    ) : (
                                        bikes.map((bike, index) => <BikeRow key={bike.id} index={index} bike={bike} setBikes={setBikes} />)
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListBikes;
